<template>
  <!--begin::Companies index for Superadmin-->
  <div v-if="companyType != companyTypesList.ID_NONE">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        :src="
          companyType == companyTypesList.ID_SENDER
            ? '/media/icons/remi-primary.svg'
            : '/media/icons/transportista-primary.svg'
        "
      />
      <div>
        {{
          companyType == companyTypesList.ID_SENDER
            ? $t("COMPANIES.SENDERS.TITLE_INDEX")
            : $t("COMPANIES.TRANSPORTERS.TITLE_INDEX")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row py-6 align-items-center">
        <div class="col-md-6 d-flex d-row align-items-center">
          <h5 class="mr-3 mb-0 font-weight-bolder" style="white-space: pre">
            {{
              companyType == companyTypesList.ID_SENDER
                ? $t("COMPANIES.SENDERS.SEARCH")
                : $t("COMPANIES.TRANSPORTERS.SEARCH")
            }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>
        <div class="col-md-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg
              v-if="ShowFilters"
              src="/media/icons/filter-alt-1.svg"
            />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>
        <div class="col-md-3 text-right p-0">
          <button
            class="btn button-filter btn-pill btn-tertiary"
            @click="downloadExcel"
            :disabled="DownloadingExcel"
          >
            <inline-svg src="/media/icons/download-alt-2.svg" class="mr-3" />
            {{ $t("COMPANIES.EXCEL_TITLE") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row py-6 box-filters">
        <div class="col-lg-4 mb-3">
          <label>
            {{ $t("COMPANIES.PROVINCE") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByProvinceID"
            @change="searchCompanies(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 mb-3">
          <label>
            {{ $t("COMPANIES.SUBSCRIPTION_TYPE") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterBySubscriptionTypeID"
            @change="searchCompanies(false)"
          >
            <option
              v-for="st in SubscriptionTypeOptions"
              :key="st.SubscriptionTypeID"
              :value="st.SubscriptionTypeID"
              class="text-primary"
            >
              {{ st.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 mb-3">
          <label>
            {{ $t("COMPANIES.STATUS") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterBySubscriptionStatusID"
            @change="searchCompanies(false)"
          >
            <option
              v-for="ss in SubscriptionStatusOptions"
              :key="ss.SubscriptionStatusID"
              :value="ss.SubscriptionStatusID"
              class="text-primary"
            >
              {{ ss.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("COMPANIES.REGISTER_DATE") }}
          </label>
          <v-text-field
            class="form-control bg-white"
            :value="formatDateRange(FilterByCreatedAtFrom, FilterByCreatedAtTo)"
            readonly
            @click="openFilterDateRangeDialog('CreatedAt')"
          />
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("COMPANIES.VALIDITY") }}
          </label>
          <v-text-field
            class="form-control bg-white"
            :value="
              formatDateRange(FilterByActiveUntilFrom, FilterByActiveUntilTo)
            "
            readonly
            @click="openFilterDateRangeDialog('ActiveUntil')"
          />
        </div>

        <div class="col-lg-4 pt-7 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::FilterDateRangeDialog dialog-->
    <v-dialog
      v-model="FilterDateRangeDialog.Show"
      :return-value="FilterDateRangeDialog.Value"
      persistent
      width="320px"
    >
      <v-date-picker
        id="FilterDateRangeDialog"
        v-model="FilterDateRangeDialog.Value"
        color="tertiary"
        scrollable
        range
        :locale="DateLocale"
        :first-day-of-week="DateFirstDayOfWeek"
      >
        <div class="row button-row">
          <button
            class="btn btn-pill btn-white button-form button-form-primary mr-9"
            style="min-width: 125px"
            @click="cancelFilterDateRangeDialog"
          >
            {{ $t("GENERIC.CANCEL") }}
          </button>
          <button
            class="btn btn-pill btn-tertiary button-form"
            style="min-width: 125px"
            @click="saveFilterDateRangeDialog"
          >
            {{ $t("GENERIC.OK") }}
          </button>
        </div>
      </v-date-picker>
    </v-dialog>
    <!--end::FilterDateRangeDialog dialog-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.Code }}
          </td>
          <td>
            {{ item.TradeName }}
          </td>
          <td>
            {{ getProvinceName(item.ProvinceID) }}
          </td>
          <td>
            {{ getSubscriptionTypeName(item.SubscriptionTypeID) }}
          </td>
          <td>
            {{ getSubscriptionStatusName(item.SubscriptionStatusID) }}
          </td>
          <td>
            {{ formatDate(item.CreatedAt) }}
          </td>
          <td>
            {{ formatDate(item.ActiveUntil) }}
          </td>
          <td>
            <span
              class="action-icon"
              @click="
                $router.push(
                  companyType == companyTypesList.ID_SENDER
                    ? '/manager/senders/edit/' + item.CompanyID
                    : '/manager/transporters/edit/' + item.CompanyID
                )
              "
            >
              <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
            </span>

            <span
              class="action-icon ml-4"
              @click="deleteCompany(item.CompanyID, item.Code, item.TradeName)"
            >
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchCompanies(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::Companies index for Superadmin-->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import CompanyService from "@/core/services/api/v2/company.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import SubscriptionStatusService from "@/core/services/api/v2/subscriptionstatus.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

export default {
  name: "CompaniesSuperadminIndex",
  computed: {
    ...mapGetters(["companyTypesList"])
  },
  data() {
    return {
      TableHeaders: [
        { text: this.$t("COMPANIES.CODE"), value: "Code" },
        { text: this.$t("COMPANIES.TRADE_NAME"), value: "TradeName" },
        { text: this.$t("COMPANIES.PROVINCE"), value: "ProvinceID" },
        {
          text: this.$t("COMPANIES.SUBSCRIPTION_TYPE"),
          value: "SubscriptionTypeID"
        },
        { text: this.$t("COMPANIES.STATUS"), value: "SubscriptionStatusID" },
        { text: this.$t("COMPANIES.REGISTER_DATE"), value: "CreatedAt" },
        { text: this.$t("COMPANIES.VALIDITY"), value: "ActiveUntil" },
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
      ],
      options: {},
      SubscriptionStatusOptions: [],
      SubscriptionStatusNames: [],
      SubscriptionTypeOptions: [],
      SubscriptionTypeNames: [],
      ProvinceOptions: [],
      ProvinceNames: [],
      SortBy: "Code",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByProvinceID: 0,
      FilterBySubscriptionTypeID: 0,
      FilterBySubscriptionStatusID: 0,
      FilterByCreatedAtFrom: "",
      FilterByCreatedAtTo: "",
      FilterByActiveUntilFrom: "",
      FilterByActiveUntilTo: "",
      FilterDateRangeDialog: {
        Show: false,
        WhichFilter: "",
        Value: []
      },
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ],
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      DownloadingExcel: false
    };
  },
  props: {
    companyType: {
      type: Number,
      required: true,
      default: 1
    }
  },

  created() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadSubscriptionStatusOptions();
    this.loadSubscriptionTypeOptions();
    this.loadProvinceOptions();
  },

  watch: {
    companyType() {
      this.loadSubscriptionTypeOptions();
      this.cleanFilters();
      this.searchCompanies(false);
    }
  },

  methods: {
    loadSubscriptionStatusOptions() {
      this.SubscriptionStatusOptions = [];
      this.SubscriptionStatusOptions.push({
        SubscriptionStatusID: 0,
        Name: " "
      });

      SubscriptionStatusService.listAll().then(response => {
        response.forEach(ss => {
          this.SubscriptionStatusOptions.push(ss);
          this.SubscriptionStatusNames[ss.SubscriptionStatusID] = ss.Name;
        });
      });
    },

    loadSubscriptionTypeOptions() {
      this.SubscriptionTypeOptions = [];
      this.SubscriptionTypeOptions.push({ SubscriptionTypeID: 0, Name: " " });

      this.SubscriptionTypeNames = [];
      this.SubscriptionTypeNames[0] = " ";

      SubscriptionTypeService.listAll(this.companyType).then(response => {
        response.forEach(st => {
          this.SubscriptionTypeOptions.push(st);
          this.SubscriptionTypeNames[st.SubscriptionTypeID] = st.Name;
        });
      });
    },

    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceOptions.push({ ProvinceID: 0, Name: " " });

      this.ProvinceNames = [];
      this.ProvinceNames[0] = " ";

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchCompanies(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(CompanyService.getCachedFilters(this.companyType)).forEach(
        f => {
          this["FilterBy" + f[0]] = f[1];

          if (!anyFilterNotEmpty) {
            anyFilterNotEmpty = parseInt(f[1]) > 0;
          }
        }
      );

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchCompanies(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      CompanyService.searchCompanies(
        this.companyType,
        this.SearchText,
        this.FilterByProvinceID,
        this.FilterBySubscriptionTypeID,
        this.FilterBySubscriptionStatusID,
        this.FilterByCreatedAtFrom,
        this.FilterByCreatedAtTo,
        this.FilterByActiveUntilFrom,
        this.FilterByActiveUntilTo,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.Companies;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchCompanies(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchCompanies(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchCompanies(false);
    },

    changeItemsCurrentPage() {
      this.searchCompanies(true);
    },

    openFilterDateRangeDialog(whichFilter) {
      this.FilterDateRangeDialog.WhichFilter = whichFilter;

      let oldValueFrom = "";
      let oldValueTo = "";

      if (whichFilter === "CreatedAt") {
        oldValueFrom = this.FilterByCreatedAtFrom;
        oldValueTo = this.FilterByCreatedAtTo;
      } else if (whichFilter === "ActiveUntil") {
        oldValueFrom = this.FilterByActiveUntilFrom;
        oldValueTo = this.FilterByActiveUntilTo;
      }

      this.FilterDateRangeDialog.Value = [oldValueFrom, oldValueTo];
      this.FilterDateRangeDialog.Show = true;
    },

    saveFilterDateRangeDialog() {
      if (this.FilterDateRangeDialog.Value.length === 2) {
        let newValues = this.FilterDateRangeDialog.Value.sort();
        let whichFilter = this.FilterDateRangeDialog.WhichFilter;

        if (whichFilter === "CreatedAt") {
          this.FilterByCreatedAtFrom = newValues[0];
          this.FilterByCreatedAtTo = newValues[1];
        } else if (whichFilter === "ActiveUntil") {
          this.FilterByActiveUntilFrom = newValues[0];
          this.FilterByActiveUntilTo = newValues[1];
        }
      }

      this.FilterDateRangeDialog.Show = false;
      this.searchCompanies(false);
    },

    cancelFilterDateRangeDialog() {
      // Reset picker to old date
      let whichFilter = this.FilterDateRangeDialog.WhichFilter;

      let oldDates = [];
      if (whichFilter === "CreatedAt") {
        oldDates = [this.FilterByCreatedAtFrom, this.FilterByCreatedAtTo];
      } else if (whichFilter === "ActiveUntil") {
        oldDates = [this.FilterByActiveUntilFrom, this.FilterByActiveUntiltTo];
      }

      this.FilterDateRangeDialog.Value = oldDates;
      this.FilterDateRangeDialog.Show = false;
    },

    cleanFilters() {
      this.FilterByProvinceID = 0;
      this.FilterBySubscriptionTypeID = 0;
      this.FilterBySubscriptionStatusID = 0;
      this.FilterByCreatedAtFrom = "";
      this.FilterByCreatedAtTo = "";
      this.FilterByActiveUntilFrom = "";
      this.FilterByActiveUntilTo = "";
      this.ShowFilters = false;
      this.searchCompanies(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchCompanies(false);
      }, 600);
    },

    getProvinceName(provinceID) {
      let name = this.ProvinceNames[provinceID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    getSubscriptionStatusName(ssID) {
      let name = this.SubscriptionStatusNames[ssID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    getSubscriptionTypeName(stID) {
      let name = this.SubscriptionTypeNames[stID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    formatDate(date) {
      return date.length > 0 ? moment(date).format(this.DateFormatLang) : "-";
    },

    formatDateRange(dateFrom, dateTo) {
      if (dateFrom === "" && dateTo === "") {
        return "";
      } else if (dateFrom === dateTo) {
        return this.formatDate(dateFrom);
      } else {
        return this.formatDate(dateFrom) + " - " + this.formatDate(dateTo);
      }
    },

    deleteCompany(companyID, companyCode, companyName) {
      Swal.fire({
        title:
          this.$i18n.t("GENERIC.DELETE_ALERT") +
          "\n" +
          companyCode +
          " - " +
          companyName,
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          CompanyService.deleteSomeCompanyProfile(companyID).then(
            statusCode => {
              if (statusCode >= 400) {
                this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                  title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                Swal.fire({
                  text: this.$i18n.t("GENERIC.DELETE_OK"),
                  icon: "success",
                  confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                  customClass: {
                    confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                  },
                  buttonsStyling: false,
                  focusConfirm: false
                });

                this.searchCompanies(true);
              }
            }
          );
        }
      });
    },

    downloadExcel() {
      // Disable download button, because this may take a while
      this.DownloadingExcel = true;

      CompanyService.exportCompanies(
        this.companyType,
        this.SearchText,
        this.FilterByProvinceID,
        this.FilterBySubscriptionTypeID,
        this.FilterBySubscriptionStatusID,
        this.FilterByCreatedAtFrom,
        this.FilterByCreatedAtTo,
        this.FilterByActiveUntilFrom,
        this.FilterByActiveUntilTo,
        this.SortBy,
        this.SortDesc
      ).then(rawResponse => {
        if (rawResponse === 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.SAVE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          const tempBlob = new Blob([rawResponse], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const tempLink = document.createElement("a");
          tempLink.href = URL.createObjectURL(tempBlob);
          tempLink.download = this.$t(
            this.companyType == this.companyTypesList.ID_SENDER
              ? "COMPANIES.SENDERS.TITLE_INDEX"
              : "COMPANIES.TRANSPORTERS.TITLE_INDEX"
          );
          tempLink.click();
          URL.revokeObjectURL(tempLink.href);
        }

        // Re-enable button
        this.DownloadingExcel = false;
      });
    }
  }
};
</script>
